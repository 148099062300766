import React from "react";
import { Link } from "gatsby";
import { slugify } from '../../utils/helpers'

class PostListing extends React.Component {
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.frontmatter.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    const headerTag = this.props.headerTag
    return (
      <section className="section">
        <div className="">
          <div className="post-list">

            { /* Your post list here. */
            postList.map((post) => (
              <div className="md:flex flex-row justify-between post" key={post.id}>
                <Link className="no-link" to={post.path}>
                    <div className="">
                        <div className="">
                          { headerTag == 'h6'?
                            <h2 className="my-1 text-lg">{post.title}</h2>
                            :
                            <h4 className="my-1">{post.title}</h4>
                          }
                        </div>
                        
                    </div>
                </Link>
                <div className="md:flex flex-col" >
                    <time className="post-stub-date">{post.date}</time>
                    {post.tags && post.tags.length ? (
                      <div className="mt-2 tags md:float-left flex space-x-2">
                        <Link to={`/tags/${slugify(post.tags[0])}/`} className='no-link' key={post.tags[0]}>
                          <span className="bg-gray-300 hover:bg-gray-400 text-gray-800 text-sm py-2 px-4 rounded whitespace-no-wrap">{post.tags[0].toUpperCase()}</span>
                        </Link>
                      </div>
                        
                      ) : null}
                  </div>

                </div>
            ))}
          </div>
          
        </div>
      </section>
    );
  }
}

export default PostListing;
