import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import config from "../../data/SiteConfig";

export default class AuthorTemplate extends React.Component {
  render() {
    const { author } = this.props.pageContext;
    const postEdges = this.props.data.allMdx.edges;
    return (
      <Layout>
        <div className="container max-w-5xl mx-auto px-8 lg:px-0">
          <div className="tag-container mb-12">
          <Helmet
            title={`Author: "${config.authors[author]}`}
          />
          <div className="content pt-12 pb-4">
              <h1 className="has-text-weight-bold is-size-2">{`Author: ${config.authors[author]['name']}`}</h1>
            </div>
          <PostListing postEdges={postEdges} />
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query AuthorPage($author: String) {
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { eq: $author } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            author
            tags
            coverImage
            date(formatString: "MMM DD, YYYY")
          }
        }
      }
    }
  }
`;
